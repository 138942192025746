import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderNavModule } from './header-nav/header-nav.module';
import { LoginFaultComponent } from './login-fault/login-fault.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import {
  AuthInterceptor,
  RequestIdInterceptor,
} from './singletons/asm.interceptor';
import { LaunchdarklyService } from './singletons/launchdarkly.service';
import { SingletonsModule } from './singletons/singletons.module';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { MsmModule } from './msm/msm.module';
import { AuthService } from './auth/auth.service';
import { Observable, mergeMap, of } from 'rxjs';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        LoginFaultComponent,
        PageNotFoundComponent,
        AboutComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HeaderNavModule,
        SingletonsModule,
        MsmModule], providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initFactory,
            deps: [AuthService, LaunchdarklyService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestIdInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

function initFactory(
  authService: AuthService,
  darklyService: LaunchdarklyService,
): () => Observable<unknown> {
  return () => {
    return authService.onInit().pipe(
      mergeMap((success) => {
        if (success) return darklyService.onInit();
        return of(true);
      }),
    );
  };
}
