type NameVal = {
  name: string;
  val: string;
};
type NameNum = {
  name: string;
  num: number;
};
export const nvfilterOptions: NameVal[] = [
  { name: 'All', val: 'all' },
  { name: 'Yes', val: 'yes' },
  { name: 'No', val: 'no' },
];

export const nvAutoExtensionTypes: NameVal[] = [
  {
    name: 'GIA_AutoExtensionPopulation1_JobDesc',
    val: 'GIA_AutoExtensionPopulation1_JobDesc',
  },
  {
    name: 'GIA_AutoExtensionPopulation2_OrgDesc',
    val: 'GIA_AutoExtensionPopulation2_OrgDesc',
  },
];
export const nvMaxAutoExtensionAllowedTypes: NameNum[] = [
  { name: 'Indefinitely', num: 0 },
  { name: '360 Days', num: 360 },
  { name: '720 Days', num: 720 },
  { name: '1080 Days', num: 1080 },
  { name: '1440 Days', num: 1440 },
  { name: '1800 Days', num: 1800 },
];
export const nvMaxAutoApprovalAllowedTypes: NameNum[] = [
  { name: 'Indefinitely', num: 0 },
  { name: '360 Days', num: 360 },
  { name: '720 Days', num: 720 },
  { name: '1080 Days', num: 1080 },
  { name: '1440 Days', num: 1440 },
  { name: '1800 Days', num: 1800 },
];
export const nvtypes: NameVal[] = [
  { name: 'Birthright', val: 'Birthright' },
  { name: 'GIA Operation', val: 'GIAOperation' },
  { name: 'GIA Security', val: 'GIASecurity' },
];

export const nvAvailableUserTypes: NameVal[] = [
  { name: 'Employee', val: 'employee' },
  { name: 'VA Employee', val: 'vaemployee' },
  { name: 'Regular Contractor', val: 'regularcontractor' },
  { name: 'Restricted Contractor', val: 'restrictedcontractor' },
  { name: 'Application ID', val: 'applicationid' },
  { name: 'Test ID', val: 'testid' },
  { name: 'Client ID', val: 'clientid' },
  { name: 'Privileged Application ID', val: 'privilegedapplicationid' },
  { name: 'Built-in', val: 'builtin' },
  { name: 'Local Admin', val: 'localadmin' },
];

export const accWideAccess = 'acc-wide-access';
export const nvGrantTypes: NameVal[] = [
  { name: 'Accenture wide-access', val: accWideAccess },
  { name: 'Geographic unit', val: 'geo-unit' },
  { name: 'Operating group', val: 'op-grp' },
  { name: 'Other dimension', val: 'other' },
];

export const nvLicensingFlags: NameVal[] = [
  { name: 'Employee', val: 'Employee' },
  { name: 'Professional', val: 'Professional' },
  { name: 'Not Applicable', val: 'NA' },
];

export const nvPrivilegedAccessFlags: NameVal[] = [
  { name: 'Not Applicable', val: 'NA' },
  { name: 'Privileged Application', val: 'PA' },
  { name: 'Privileged Access - Services', val: 'PA_SVC' },
  { name: 'Privileged Access - Tier 0', val: 'PA_T0' },
  {
    name: 'Privileged Access - Azure Directory Service Accounts',
    val: 'PA_AZR_SVC',
  },
  {
    name: 'Privileged Access - On-Prem Directory Service Accounts',
    val: 'PA_AD_SVC',
  },
  { name: 'Privileged Access - Azure Directory Accounts', val: 'PA_AZR' },
  {
    name: 'Privileged Access - Azure Directory Accounts - Tier 0',
    val: 'PA_AZR_T0',
  },
  { name: 'Privileged Access - On-Prem Directory Accounts', val: 'PA_AD' },
  {
    name: 'Privileged Access - On-Prem Directory Accounts - Tier 0',
    val: 'PA_AD_T0',
  },
];

export const privacyQuestions = {
  othersDetails: `Does your role provide access to other's personal details?`,
  othersData: `Does your roles provide access to other's sensitive data?`,
  othersAudiovisual: `Does the role provide access to other's audiovisual materials?`,
  immigrationLaws: `Does the role provide access to any documentation required under immigration laws?`,
  othersCompensation: `Does the role provide access to other's compensation and payroll?`,
  contractual: `Does the role provide access to other's position and contractual information?`,
  talentManagement: `Does the role provide access to other's talent management information?`,
  managementRecords: `Does the role provide access to other's management records?`,
  systemAccessData: `Does the role provide access to other's system and application access data?`,
};
export const privacyQuestionKeys = Object.keys(privacyQuestions);

export const dataQuestions = {
  nonBusinessRelated: `Is the content non-business related public data?`,
  publicFacing: `Is the content intended to be generally available to the public outside of Accenture?`,
  securities: `Could someone consider the content that the role grant access important in deciding whether to buy or sell securities?`,
  jobReliant: `Should the content only be shared with specific Accenture employees or external parties who needs the information to do their jobs?`,
  shareInternally: `Would it be appropriate to share this content broadly across Accenture?`,
};

export const nvPrivilegedAccess: NameVal[] = [
  { name: 'Not applicable', val: 'NA' },
  { name: 'Privileged access', val: 'PA' },
  { name: 'Privileged access w/ JIT', val: 'BOTH' },
];

export const nvInsiderAlert: NameVal[] = [
  { name: 'Not Insider', val: 'Not Insider' },
  { name: 'Potential Insider', val: 'Potential Insider' },
  { name: 'Insider', val: 'Insider' },
];

export const nvDataClassification: NameVal[] = [
  { name: 'Restricted', val: 'Restricted' },
  { name: 'Highly Confidential', val: 'Highly Confidential' },
  { name: 'Confidential', val: 'Confidential' },
  { name: 'Unrestricted', val: 'Unrestricted' },
];

export const Questionnaires = {
  DC: 'Data classification',
  DP: 'Data privacy',
  IR: 'Insider role',
};

export const errMgs = {
  paErr:
    'If PrivilegedAccessFlag is one of the following: Privileged Access - Tier 0, Privileged Access - Azure Directory Accounts - Tier 0, Privileged Access - On-Prem Directory Accounts - Tier 0 then the max value is 90. Otherwise, the max value is 180.',
};
