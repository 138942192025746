import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { finalize, last, switchMap, tap } from 'rxjs/operators';
import { IActionPkg } from '../models/Actions';
import { msmActions } from '../msm/actions-objective';
import { IngestionService } from './ingestion.service';
import { awsHeader } from '../models/ISignedUrl';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.method === 'PUT') {
      // unsigned url doesn't need auth bearer
      // ATM only the unsigned url uses a PUT
      return next.handle(
        req.clone({
          headers: req.headers.set('Content-Type', 'text/csv'),
        }),
      );
    }
    return this.authService.refreshToken().pipe(
      last(),
      switchMap((canProceed) => {
        if (canProceed) {
          if (req.headers.get(awsHeader)) {
            return next.handle(req);
          }
          return next.handle(
            req.clone({
              headers: req.headers.set(
                'Authorization',
                'Bearer ' + this.authService.currUser?.accessToken,
              ),
            }),
          );
        } else {
          throw new Error('Failed to refresh token');
        }
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class RequestIdInterceptor implements HttpInterceptor {
  private readonly urlAp = 'applications';
  private readonly urlEd = 'entitlementdimensions';
  private readonly urlBr = 'businessroles';
  private readonly urlDi = 'dimensions';
  private readonly urlDt = 'dimensiontypes';
  private readonly urlDs = 'dataselections';
  private readonly urlDsg = 'dataselectiongroups';
  private readonly urlAc = 'accesspackages';
  private readonly urlRf = 'rolefilters';
  private readonly urlSd = 'segregationofduties';
  private readonly urlAoi = 'areaofinterests';

  constructor(private ingService: IngestionService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'POST') {
      return next.handle(req);
    }
    const i = req.url.indexOf('securitymodel', 25);
    const currUrl = req.url.substring(i > 0 ? i + 13 : 0);
    const action = this.getAction(currUrl);
    if (action) {
      if (this.ingService.api.hasReqId()) {
        return this.setReqId(this.ingService.api.getReqId(), req, next);
      } else {
        return this.ingService.api.getNoSetReqId().pipe(
          last(),
          switchMap((data) => {
            let success: HttpResponse<any>;
            return this.setReqId(data.requestID, req, next).pipe(
              tap({
                next: (event) => {
                  success = event instanceof HttpResponse ? event : null;
                },
              }),
              // Check if 'Skip-Ingestion' header is present
              finalize(() => {
                if (success && !req.headers.get('Skip-Ingestion')) {
                  this.ingService.api
                    .postIngestions(success.body.requestID, action)
                    .subscribe();
                }
              }),
            );
          }),
        );
      }
    }
    return next.handle(req);
  }

  private setReqId(
    reqId: string,
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req.body.requestID = reqId;
    return next.handle(
      req.clone({
        body: req.body,
      }),
    );
  }

  private getAction(url: string): IActionPkg {
    if (url.indexOf(this.urlAp) > 0) {
      return msmActions.updateApp;
    } else if (url.indexOf(this.urlBr) > 0) {
      return msmActions.updateBr;
    } else if (url.indexOf(this.urlEd) > 0) {
      return msmActions.updateEdm;
    } else if (url.indexOf(this.urlDi) > 0) {
      return msmActions.updateDims;
    } else if (url.indexOf(this.urlDt) > 0) {
      return msmActions.updateDimTypes;
    } else if (url.indexOf(this.urlDs) > 0) {
      return msmActions.updateDataSelections;
    } else if (url.indexOf(this.urlDsg) > 0) {
      return msmActions.updateDataSelectionGroups;
    } else if (url.indexOf(this.urlAc) > 0) {
      return msmActions.updateAccPkg;
    } else if (url.indexOf(this.urlRf) > 0) {
      return msmActions.updateRoleFilters;
    } else if (url.indexOf(this.urlSd) > 0) {
      return msmActions.updateSod;
    } else if (url.indexOf(this.urlAoi) > 0) {
      return msmActions.updateAreaOfInterests;
    }
    return undefined;
  }
}
